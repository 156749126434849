import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next).init({
  ns: ['common'],
  defaultNS: 'common',
  // resources,
  fallbackLng: localStorage.getItem('current_locale') || 'en',
  backend: {
    loadPath: `${process.env.PUBLIC_URL}/locales/${window.tenant}/{{lng}}/{{ns}}.json`,
  }
});

export default i18n;
